.cube { transform-style: preserve-3d; }
figure { transform-origin: 50% 50% 0; margin: auto; }
.front { transform: translateZ(150px); background-color: rgb(115, 243, 115);}
.back { transform: rotateY(180deg) translateZ(150px); background-color: #efe7b1; }

.stage {
    width: 300px;
    height: 300px;
    perspective: 1600px;
    perspective-origin: 90% -240px;
}


figure {
    display: block;
    position: absolute;
    width: 300px; 
    height: 300px;
    background-color: #60c2ef;
}



.top { transform: rotateX(90deg) translateZ(150px); background-color: rgb(233, 125, 125);}
.bottom { transform: rotateX(-90deg) translateZ(150px); background-color: rgb(207, 207, 151);}
.left { transform: rotateY(-90deg) translateZ(150px); background-color: #6acaa4;}
.right { transform: rotateY(90deg) translateZ(150px); background-color: #a5cde0;}

@keyframes rotate {
    0% { transform:  rotateX(0deg) rotateY(0deg); }
    100% {  transform: rotateX(0deg) rotateY(360deg); }
}

.cube { animation: rotate 10s infinite linear; }

  